import PropTypes from "prop-types";
import { useTranslation } from "react-i18next/dist/es/useTranslation";

import { createClassName } from "../../helpers";
import "./style.scss";

const cn = createClassName("time-line");

const TimeLine = (props) => {
  const { t } = useTranslation("common");
  const { versionList } = props;

  const version = versionList.map((item, index) => (
    <div
      key={index}
      className={
        item.current ? cn("version", { current: true }) : cn("version")
      }
    >
      <span>{item.version}</span>
    </div>
  ));

  const currentVersion = versionList.map((item, index) => (
    <div key={index} className={cn({ designation: true })}>
      <span
        className={
          item.current
            ? cn({ designation: "current" })
            : cn({ designation: "uncurrent" })
        }
      >
        {t("current-version")}
      </span>
    </div>
  ));

  return (
    <div className={cn()}>
      <div className={cn({ wrap: true })}>{version}</div>
      <div className={cn({ scale: true })}>
        <div className={cn({ scale: "now" })} />
        <div className={cn({ scale: "feature" })} />
      </div>
      <div className={cn({ wrap: true })}>{currentVersion}</div>
    </div>
  );
};

TimeLine.propTypes = {
  versionList: PropTypes.arrayOf(PropTypes.object),
};

export default TimeLine;
